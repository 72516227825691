@import '@assets/scss/v2/main';

.countrySelector {
  display: flex;
  flex-direction: column;
  justify-content: center;

  max-width: 30rem;
}

.countryName {
  font-size: 2rem;
  color: $blue-bright;
  margin-bottom: 1.6rem;
  text-align: center;
  font-family: Quicksand-Bold;
}

.countryLanguage {
  margin-bottom: 1.6rem;
  font-family: Sen-Regular;
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 0.5px;
  padding: 1rem 1.5rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s;
  position: relative;
  cursor: pointer;
  border-radius: 24px;
  min-width: 240px;
  min-height: 40px;
  line-height: 1;
  color: $blue-dark;
  background-color: #fff;
  border: 2px solid $blue-dark;

  &:hover {
    transform: translateY(-6px);
  }
}
