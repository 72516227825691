@import '@assets/scss/v2/main';

.countrySelectorComponent {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.backgroundImage {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: -1;
  object-fit: fill;
}

.container {
  width: 95%;
  max-width: 62rem;

  @include respond(l) {
    max-width: 80rem;
  }
}

.topSection {
  background-color: $white;
  padding: 1.6rem;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  border-top-left-radius: 2rem;
  border-top-right-radius: 2rem;
  text-align: center;

  @include respond(l) {
    padding: 4rem;
  }
}

.logo {
  margin-bottom: 0;
  width: 100%;
  max-width: 54rem;
  padding: 0 2.5rem;

  @include respond(l) {
    margin-bottom: 4rem;
    padding: 0;
  }
}

.rent {
  font-size: 1.6rem;
  font-weight: 700;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  font-family: Quicksand-Bold;
  color: $blue-dark;

  @include respond(l) {
    font-size: 2.4rem;
    line-height: 1.33;
  }
}

.bottomSection {
  padding: 2rem;
  background-color: #edf1f7;
  border-bottom-left-radius: 2rem;
  border-bottom-right-radius: 2rem;

  @include respond(l) {
    padding: 4.8rem;
  }
}

.please {
  margin-bottom: 2.4rem;
  text-align: center;
  font-size: 1.8rem;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  font-family: Quicksand-Bold;

  @include respond(l) {
    margin-bottom: 4rem;
  }
}

.countrySelectorWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  @include respond(l) {
    align-items: baseline; // desktop
    flex-direction: row; // desktop
    justify-content: space-around;
  }
}
